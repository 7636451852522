/* You can add global styles to this file, and also import other style files */
@font-face 
    font-family: "Inter-Light"
    src: local("Inter-Light"), url('./assets/fonts/Inter-Light.ttf') format("truetype")

@font-face 
    font-family: "Inter-Regular"
    src: local("Inter-Regular"), url('./assets/fonts/Inter-Regular.ttf') format("truetype")

@font-face 
    font-family: "Inter-Medium"
    src: local("Inter-Medium"), url('./assets/fonts/Inter-Medium.ttf') format("truetype")

@font-face 
    font-family: "Inter-SemiBold"
    src: local("Inter-SemiBold"), url('./assets/fonts/Inter-SemiBold.ttf') format("truetype")

@font-face 
    font-family: "Inter-Bold"
    src: local("Inter-Bold"), url('./assets/fonts/Inter-Bold.ttf') format("truetype")


:root
    --green-primary: #80004B
    --red-primary: #FF394C
    --bg-black: #000
    --bg-white: #fff
    --bg-background: #F5F5F5
    --bg-menu: #5F5D5D
    --border-color: #AFAFAF
    --inter: "Inter-Regular"
    --inter-l: "Inter-Light"
    --inter-m: "Inter-Medium"
    --inter-s: "Inter-SemiBold"
    --inter-b: "Inter-Bold"

mat-drawer-container
    height: 100% !important

/* colores para pruebas de maquetado */
.red
    background: red !important
.blue
    background: blue !important
.orange
    background: orange !important
.green
    background: green !important
.yellow
    background: yellow !important
.pink
    background: pink !important

::-webkit-scrollbar 
    width: 12px
  
::-webkit-scrollbar-track 
    background: var(--bg-background)
    width: 12px
  
::-webkit-scrollbar-thumb 
    border-radius: 10px
    background: var(--green-primary)   
    &:hover 
       background: var(--green-primary)
    &:active 
       background: var(--green-primary) 

.mat-step-icon .mat-icon 
    font-size: 18px !important
    height: 18px !important
    width: 18px !important

.mat-step-text-label
    transition: all 1s
    font-family: var(--inter) !important
    font-size: 13px !important

.mat-step-label-selected
    .mat-step-text-label
        transition: all 1500ms
        font-family: var(--inter-s) !important

.mat-step-icon
    transition: all 1500ms
    background-color: white !important
    border: 2px solid #CFCFCF
    width: 32px !important
    height: 32px !important
    *
        transition: all 1500ms
        color: #CFCFCF !important

.mat-step-icon-selected
    transition: all 1500ms
    background-color: #EFF8F0 !important
    border: 2px solid var(--green-primary)
    *
        transition: all 1500ms
        color: var(--green-primary) !important  

.mdc-text-field--filled:not(.mdc-text-field--disabled) 
    background-color: whitesmoke
    border-radius: 5px
    border: 1px solid #AFAFAF
    max-height: 52px
    
.mat-form-field-should-float
    border: 1px solid var(--green-primary) !important

.mapboxgl-ctrl-attrib
    display: none !important

