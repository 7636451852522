// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$bolsa-color-primary: (
  50 : #ecf6ed,
  100 : #cfead2,
  200 : #afdcb5,
  300 : #8ecd97,
  400 : #80004B,
  500 : #80004B,
  600 : #56b162,
  700 : #4ca857,
  800 : #42a04d,
  900 : #31913c,
  A100 : #daffdd,
  A200 : #a7ffb0,
  A400 : #74ff82,
  A700 : #5aff6b,
  contrast: (50: rgba(0, 0, 0, 1),
    100: rgba(0, 0, 0, 1),
    200: rgba(0, 0, 0, 1),
    300: rgba(0, 0, 0, 1),
    400: rgba(255, 255, 255, 1),
    500: rgba(255, 255, 255, 1),
    600: rgba(255, 255, 255, 1),
    700: rgba(255, 255, 255, 1),
    800: rgba(255, 255, 255, 1),
    900: rgba(255, 255, 255, 1))
);

$bolsa-color-black: (
  50: #f5f5f5,
  100: #e9e9e9,
  200: #d9d9d9,
  300: #c4c4c4,
  400: #9d9d9d,
  500: #7b7b7b,
  600: #555555,
  700: #434343,
  800: #262626,
  900: #000000,
  contrast: (50: rgba(0, 0, 0, 1),
    100: rgba(0, 0, 0, 1),
    200: rgba(0, 0, 0, 1),
    300: rgba(0, 0, 0, 1),
    400: rgba(0, 0, 0, 1),
    500: rgba(255, 255, 255, 1),
    600: rgba(255, 255, 255, 1),
    700: rgba(255, 255, 255, 1),
    800: rgba(255, 255, 255, 1),
    900: rgba(255, 255, 255, 1))
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bolsa-de-trabajo-front-primary: mat.define-palette($bolsa-color-primary, 400);
$bolsa-de-trabajo-front-accent: mat.define-palette($bolsa-color-primary, 500, 600, 700);

// The warn palette is optional (defaults to red).
$bolsa-de-trabajo-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bolsa-de-trabajo-front-theme: mat.define-light-theme((color: (primary: $bolsa-de-trabajo-front-primary,
        accent: $bolsa-de-trabajo-front-accent,
        warn: $bolsa-de-trabajo-front-warn )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bolsa-de-trabajo-front-theme);


html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  background-color: #F5F5F5;
}

.custom-text-primary {
  color: mat.get-color-from-palette($bolsa-color-primary, 400);
}

.custom-selected::before {
  content: '';
  width: 10px;
  background-color: mat.get-color-from-palette($bolsa-color-primary, 400);
}

.custom-option::before {
  content: '';
  width: 10px;
}

.custom-option:not(.custom-selected):hover {
  background-color: mat.get-color-from-palette($bolsa-color-primary, 400);
  div, mat-icon {
    color: mat.get-contrast-color-from-palette($bolsa-color-primary, 400);
  }
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #80004B;
}